<template>
  <section id="team" class="team">
    <h2 class="team-title">Наша команда</h2>
    <swiper
      :modules="modules"
      :slides-per-view="1"
      :space-between="10"
      :navigation="swiperOptions.navigation"
      :autoplay="{
        delay: 50000,
        disableOnInteraction: false,
      }"
      :pagination="{ clickable: true }"
    >
      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/8.jpg" />
            <div class="team__employee__content-text">
              <p>
                Сотрудники компании «Автобан» — настоящие профессионалы своего
                дела и главное достояние нашей компании. Клиентоориентированные,
                целеустремленные - сотрудники высокого класса. Секрет компании -
                мы настоящая команда, не только работающая на общий результат,
                но и скрепленная атмосферой уважения и поддержки со стороны
                руководителей и своих коллег. Компания Автобан делает все для
                привлечения лучших специалистов и содействует их
                профессиональному развитию, а это залог предоставления
                качественных услуг, надежного и долгосрочного партнерства.
              </p>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/98.jpg" alt="Ткачев Константин" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Ткачев Константин</h3>
              <h3 class="team__employee-job">
                Директор по сервисному обслуживанию
              </h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img
              src="../../img/pics/team/osotov_artem.jpg"
              alt="Осотов Артём"
            />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Осотов Артём</h3>
              <h3 class="team__employee-job">руководитель отдела продаж</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/22.jpg" alt="Липенских Максим" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Липенских Максим</h3>
              <h3 class="team__employee-job">специалист отдела трейд ин</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/107.jpg" alt="Филимонова Олеся" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Филимонова Олеся</h3>
              <h3 class="team__employee-job">логист</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/50.jpg" alt="Наумова Мария" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Наумова Мария</h3>
              <h3 class="team__employee-job">администратор</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/70.jpg" alt="Зенченко Евгения" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Зенченко Евгения</h3>
              <h3 class="team__employee-job">руководитель клиентской службы</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/03.jpg" alt="Савиных Ксения" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Савиных Ксения</h3>
              <h3 class="team__employee-job">менеджер отдела продаж</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/88.jpg" alt="Удилин Андрей" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Удилин Андрей</h3>
              <h3 class="team__employee-job">мастер-приемщик</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img
              src="../../img/pics/team/kurdoyakov_evgenii.jpg"
              alt="Курдояков Евгений"
            />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Курдояков Евгений</h3>
              <h3 class="team__employee-job">менеджер отдела продаж</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/101.jpg" alt="Мышкин Павел" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Мышкин Павел</h3>
              <h3 class="team__employee-job">специалист отдела трейд ин</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/111.jpg" alt="Мингалева Ольга" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Мингалева Ольга</h3>
              <h3 class="team__employee-job">
                менеджер кредитно-страхового отдела
              </h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/13.jpg" alt="Бондаренко Даниил" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Бондаренко Даниил</h3>
              <h3 class="team__employee-job">менеджер отдела продаж</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/16.jpg" alt="Стенников Иван" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Стенников Иван</h3>
              <h3 class="team__employee-job">менеджер отдела продаж</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img
              src="../../img/pics/team/gumenuk_maksim.jpg"
              alt="Гуменюк Максим"
            />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Гуменюк Максим</h3>
              <h3 class="team__employee-job">менеджер отдела продаж</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/45.jpg" alt="Трофимова Марина" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Трофимова Марина</h3>
              <h3 class="team__employee-job">
                менеджер кредитно-страхового отдела
              </h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/31.jpg" alt="Викторова Ольга" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Викторова Ольга</h3>
              <h3 class="team__employee-job">менеджер отдела продаж</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img
              src="../../img/pics/team/svetlolobova_nataliya.jpg"
              alt="Светлолобова Наталья"
            />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Светлолобова Наталья</h3>
              <h3 class="team__employee-job">менеджер кредитного отдела</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/39.jpg" alt="Ужегов Евгений" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Ужегов Евгений</h3>
              <h3 class="team__employee-job">специалист отдела трейд ин</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img
              src="../../img/pics/team/savinceva_anastasiya.jpg"
              alt="Савинцева Анастасия"
            />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Савинцева Анастасия</h3>
              <h3 class="team__employee-job">менеджер кредитного отдела</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/71.jpg" alt="Ошкукова Наталья" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Ошкукова Наталья</h3>
              <h3 class="team__employee-job">
                менеджер кредитно-страхового отдела
              </h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/98-b.jpg" alt="Семенов Алексей" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Семенов Алексей</h3>
              <h3 class="team__employee-job">мастер-приемщик отдела сервиса</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <button class="prev-slide-team"></button>
      <button class="next-slide-team"></button>
    </swiper>
  </section>
</template>

<script>
import siteData from "@/config.json";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Scrollbar, A11y, Navigation, Pagination, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
export default {
  name: "TeamComponent",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Scrollbar, A11y, Navigation, Pagination, Autoplay],
      swiperOptions: {
        navigation: {
          nextEl: ".next-slide-team",
          prevEl: ".prev-slide-team",
        },
      },
    };
  },
};
</script>
